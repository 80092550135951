import React from 'react'
import tw from 'twin.macro'
import parse from 'html-react-parser'
import EmfImage from '@elements/EmfImage'

const Section = tw.section`px-offset-xs md:px-0 md:pt-spacingXl-xs xl:pt-spacingXl-lg`

const Headline = tw.h2`relative md:absolute w-2/3 md:w-1/2 top-0 md:-right-offset-xs md:translate-x-full mb-spacingXs-xs md:mb-0 z-10 md:-translate-y-1/2`
const ImageWrap = tw.div`relative md:flex md:w-5/12 lg:w-7/12 h-full`

const Milestones = tw.div`w-full md:w-7/12 lg:w-5/12 flex pt-10 md:pt-0`
const MilestonesWrap = tw.div`w-full sm:px-16 md:px-10 2xl:px-16 mt-auto h-3/4 md:w-10/12 xl:w-3/4 overflow-y-auto`
const SingleMilestone = tw.div`px-3 py-4 border-b border-b-light`
const Name = tw.p`text-copyXl`
const Description = tw.p`text-sm`

const WpAcfMilestonesBlock = ({ data }) => {
	const module = data.modulesMilestones

	if (!module.items) return ''
	return (
		module.items?.length > 0 && (
			<Section className='emf-pb-spacing'>
				<div className='wrap md:h-[450px] lg:h-[680px] md:flex'>
					<ImageWrap>
						<Headline className='headline-h2'>{parse(module.headline)}</Headline>
						{module.image?.localFile && <EmfImage src={module.image.localFile} className='h-80 md:h-full' altText={module.image.altText} />}
					</ImageWrap>
					<Milestones>
						<MilestonesWrap>
							{module.items.map((milestone, index) => {
								return (
									<SingleMilestone key={`${index}-milestone`}>
										<Name>{milestone.name}</Name>
										<Description>{parse(milestone.description)}</Description>
									</SingleMilestone>
								)
							})}
						</MilestonesWrap>
					</Milestones>
				</div>
			</Section>
		)
	)
}

export default WpAcfMilestonesBlock
